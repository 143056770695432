import React, { useRef, useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import './App.css';
import customCss from './custom.css';
import logo from './mpro-isotype.svg';
import whiteLogo from './mpro-logo-white.svg';
import Theme from './styles';
import testimonials from './testimonials';
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronUp,
  faArrowDown,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';
import Modal from 'react-modal';
import ContactForm from './components/ContactForm';

function App() {
  const playerRef = useRef(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    document.title = 'Oportunidad - Momentum Pro';
  }, []);

  const playerConfig = {
    file: {
      attributes: {
        controlsList: 'nodownload', // Hide download button
      },
    },
  };

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Add smooth scrolling behavior
    });
    console.log('top');
  };

  const modalStyles = {
    overlay: { backgroundColor: 'rgba(0,0,0,0.7)', zIndex: 1000 },
    content: {
      width: window.innerWidth > 900 ? '80%' : '100%',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      padding: 0,
      borderRadius: 30,
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'transparent',
      border: 'none',
    },
  };

  return (
    <div className='App'>
      <Theme />
      <header className='App-header'>
        <img src={logo} className='App-logo' alt='logo' />
        <h1 className='text'>Expertos en marketing colaborativo</h1>
        <motion.h2
          className='text-box'
          // transition={{ type: 'spring', stiffness: 300 }}
          animate={{ scale: [1, 1.05, 1] }}
          transition={{ repeat: Infinity, duration: 3 }}
        >
          ¿Quieres elevar tus ingresos mediante tu propio negocio digital?{' '}
          <FontAwesomeIcon icon={faArrowDown} />
        </motion.h2>
        <div>
          <ReactPlayer
            ref={playerRef}
            url='https://eddy.mx/videos/mpro.mp4'
            playing={true}
            muted={true}
            loop={true}
            controls={true}
            playsinline={true}
            config={playerConfig}
            width={'100%'}
            height={'100%'}
            className='video'
          />
          <motion.button
            className='download-button'
            whileHover={{ scale: 1.2 }}
            transition={{ type: 'spring', stiffness: 200 }}
            onClick={() => setShowModal(true)}
          >
            Agendar masterclass ahora
          </motion.button>
        </div>
        <br></br>
        <h1>Testimonios de emprendedores</h1>
        <Slider
          autoplay={3000}
          infinite={true}
          previousButton={null}
          nextButton={null}
          classNames={customCss}
          minSwipeOffset={'100'}
        >
          {testimonials.map((item, index) => (
            <div className='container' key={index}>
              <div className='card'>
                <img
                  src={item.image}
                  className='person-image'
                  alt={`person ${item.id}`}
                ></img>
                <p className='name'>{item.name}</p>
                <p className='profession'>{item.profession}</p>
                <p className='location'> {item.location}</p>
                <p className='review'>"{item.review}"</p>
              </div>
            </div>
          ))}
        </Slider>
        <motion.button
          className='download-button'
          whileHover={{ scale: 1.2 }}
          transition={{ type: 'spring', stiffness: 200 }}
          onClick={() => setShowModal(true)}
        >
          Agendar masterclass ahora
        </motion.button>
        <button className='scroll-to-top-button' onClick={handleScrollToTop}>
          <FontAwesomeIcon icon={faChevronUp} />
        </button>
        <div className='footer'>
          <img src={whiteLogo} className='App-logo' alt='logo' />
          <p className='copyright'>
            Copyright © {new Date().getFullYear()} - Momentum Pro
          </p>
        </div>
      </header>
      <Modal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        style={modalStyles}
        ariaHideApp={false}
        contentLabel='Create Event Modal'
      >
        <motion.button
          className='close-icon'
          onClick={() => setShowModal(false)}
          whileHover={{ scale: 1.3 }}
          size='20'
          transition={{ type: 'spring', stiffness: 200 }}
        >
          <FontAwesomeIcon icon={faTimes} style={{ fontSize: '1.5rem' }} />
        </motion.button>
        <div className='modal-container'>
          <ContactForm setShowModal={setShowModal}></ContactForm>
        </div>
      </Modal>
    </div>
  );
}

export default App;
