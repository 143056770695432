import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
import '../../App.css';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Swal from 'sweetalert2';
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
} from 'react-google-recaptcha-v3';

const ContactForm = (props) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    datetime: '',
    timezone: 'CDMX',
  });
  const [recaptcha, setRecaptcha] = useState(false);

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 600); // Change the threshold based on your desired breakpoint
    };

    // Initial check on mount
    handleResize();

    // Event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleChange = (e) => {
    // Handle other input fields
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Replace these values with your Email.js service ID, template ID, and user ID
    const serviceID = 'service_kzwfp07';
    const templateID = 'template_2ykzj1q';
    const userID = 'j7kMArg3FTd6Lf4L0';

    if (!recaptcha) {
      console.log('Recaptcha failed');
      return;
    }

    emailjs
      .sendForm(serviceID, templateID, e.target, userID)
      .then((response) => {
        console.log('Email sent successfully:', response);
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          datetime: '',
          timezone: 'CDMX',
        });
        props.setShowModal(false);
        Swal.fire('¡Éxito!', 'Masterclass agendada', 'success');
        // Custom gtag event for email sent
        window.gtag('event', 'email_sent', {
          event_category: 'Contact Form M Pro',
          event_label: 'Email Sent',
        });
      })
      .catch((error) => {
        console.error('Error sending email:', error);
      });
  };

  const generateOptions = () => {
    const options = [];
    const now = new Date();
    const currentHour = now.getHours();
    const startHour = currentHour >= 8 ? currentHour + 2 : 8;

    for (let hour = startHour; hour <= 22; hour += 2) {
      const optionDate = new Date(now);
      optionDate.setHours(hour, 0, 0, 0);

      // Add options only between 8 am and 10 pm
      if (optionDate.getHours() >= 8 && optionDate.getHours() <= 22) {
        options.push({
          value: optionDate.toISOString(),
          label: optionDate.toLocaleString('es-Es', {
            weekday: 'long',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
          }),
        });
      }
    }

    // Add options for the next day
    for (let hour = 8; hour <= 22; hour += 2) {
      const optionDate = new Date(now);
      optionDate.setDate(now.getDate() + 1); // Set date to the next day
      optionDate.setHours(hour, 0, 0, 0);

      // Add options only between 8 am and 10 pm
      if (optionDate.getHours() >= 8 && optionDate.getHours() <= 22) {
        options.push({
          value: optionDate.toISOString(),
          label: optionDate.toLocaleString('es-Es', {
            weekday: 'long',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
          }),
        });
      }
    }

    return options;
  };

  return (
    <div
      style={{
        boxSizing: 'border-box',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: isSmallScreen ? '30px 10px' : '50px',
      }}
    >
      <h1>Masterclass en marketing colaborativo</h1>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: isSmallScreen ? 'column' : 'row', // Change flex direction based on screen size
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <form
          onSubmit={handleSubmit}
          style={{
            width: '100%',

            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <br></br>
          <label>Zona horaria</label>
          <select
            label='Zona horaria'
            variant='filled'
            id='timezone'
            name='timezone'
            defaultValue='CDMX'
            required
            // helperText='Please select a category'
            value={formData.timezone}
            onChange={handleChange}
            style={{
              width: '80%',
              backgroundColor: '#E8E8E8',
              fontSize: 16,
              padding: '10px',
              margin: 10,
            }}
          >
            <option value='CDMX'>CDMX (GMT-7)</option>
            <option value='Pacifico'>Pacífico (GMT-8)</option>
            <option value='Central'>Central (GMT-6)</option>
            <option value='Este'>Este (GMT-5)</option>
          </select>
          <label>Horarios disponibles</label>
          <select
            label='Fecha y hora'
            variant='filled'
            id='datetime'
            name='datetime'
            required
            // helperText='Please select a category'
            value={formData.datetime}
            onChange={handleChange}
            style={{
              width: '80%',
              backgroundColor: '#E8E8E8',
              fontSize: 16,
              padding: '10px',
              margin: 10,
            }}
          >
            {generateOptions().map((option) => (
              <option
                key={option.label}
                value={option.label}
                style={{ color: 'black' }}
              >
                {option.label}
              </option>
            ))}
          </select>

          <TextField
            label='Nombre(s)'
            variant='filled'
            type='text'
            id='firstName'
            name='firstName'
            value={formData.firstName}
            onChange={handleChange}
            required
            style={{
              width: '80%',
              backgroundColor: 'white',
              margin: 10,
            }}
          />
          <TextField
            label='Apellido(s)'
            variant='filled'
            type='text'
            id='lastName'
            name='lastName'
            value={formData.lastName}
            onChange={handleChange}
            required
            style={{
              width: '80%',
              backgroundColor: 'white',
              margin: 10,
            }}
          />

          <TextField
            label='Email'
            variant='filled'
            type='email'
            id='email'
            name='email'
            value={formData.email}
            onChange={handleChange}
            required
            style={{
              width: '80%',
              backgroundColor: 'white',
              margin: 10,
            }}
          />

          <Button
            variant='contained'
            type='submit'
            style={{
              backgroundColor: '#162a90',
            }}
          >
            Agendar
          </Button>
          <GoogleReCaptchaProvider reCaptchaKey='6LdG5WEpAAAAAJ0OvTlOtN1uT8M6jmD0DTIU7qbY'>
            {/* <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_RECAPTCHA_SITE_KEY}> */}
            <GoogleReCaptcha onVerify={() => setRecaptcha(true)} />
          </GoogleReCaptchaProvider>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
