import rodrigo from './images/rodrigo-chavez.png';
import mijailelia from './images/mijail-y-elia.png';
import rocio from './images/rocio-sanchez.png';
import carlos from './images/carlos-ramos.png'
import janet from './images/janet-covarrubias.png'

const testimonials = [
  {
    id: 1,
    name: 'Rodrigo Chávez',
    profession: 'Abogado',
    location: 'Jalisco, México',
    review:
      'Cuando era más joven, siempre me prometieron que el éxito estaría “asegurado” al concluir mi carrera profesional. Sin embargo, en mis primeros años trabajando en un despacho de abogados, me di cuenta que mis ganancias no reflejaban el tiempo y compromiso invertido en mi empleo. Mi frustración crecía al ver que mis gastos aumentaban y yo no tenía opciones claras de cómo ganar más. En la búsqueda de alternativas para mejorar mi situación financiera, descubrí este proyecto de marketing y dropshipping. Tomar la decisión de emprender ha resultado en un notable incremento de mis ingresos, prácticamente duplicando lo que ganaba en mi empleo anterior.',
    image: rodrigo,
  },
  {
    id: 2,
    name: 'Dr. Mijail González y Dra. Elia Novelo',
    location: 'Yucatán, México',
    review:
      'Como profesionales de la salud, nuestros ingresos estaban limitados únicamente a las consultas que ofrecíamos. El estrés alcanzó un punto crítico, llevándonos a cuestionar si este camino nos llevaría realmente a nuestras metas financieras considerando que ambos trabajábamos jornadas completas. Emprender mediante un concepto tan potente como el marketing apalancado, nos ha revolucionado nuestra visión del futuro permitiéndonos duplicar nuestros ingresos sin la necesidad de aumentar la carga de consultas.',
    image: mijailelia,
  },
  {
    id: 3,
    name: 'Rocío Sánchez',
    location: 'Guanajuato, México',
    review:
      'Siendo una abuela joven, sentía que el mercado no ofrecía oportunidades para mí. Aunque estaba “atrapada” en mi rol de ama de casa y al mismo tiempo apasionada del deporte de remo, quería volver a sentir la adrenalina de emprender. Conocí este modelo de negocios que no solo me ayudó a generar ingresos, sino que también me permitió combinar mi amor por el deporte. Ahora, no solo he superado las limitaciones económicas, también he podido participar en competencias internacionales respaldada por un negocio en constante crecimiento.',
    image: rocio,
  },
  {
    id: 4,
    name: 'Carlos Ramos',
    profession: 'Ing. en Sistemas',
    location: 'Sonora, México',
    review:
      'Cuando tomé la decisión de hacer este negocio, era la tercera vez que me platicaban la propuesta. En las dos anteriores, me encontraba trabajando en un puesto directivo para una empresa multinacional y pensaba que me iba muy bien económicamente. Hoy en retrospectiva digo: ¿Comparado con qué? Sin embargo, la vida da muchas vueltas y al paso de los años trabajando de manera independiente me encontraba en un momento de estrés laboral y buscando opciones financieras. Es ahí cuando un amigo de la universidad me platicó de nuevo el proyecto y decidí iniciarlo. A la vuelta de 2-3 años yo ya había superado mis ingresos anteriores',
    image: carlos,
  },
  {
    id: 5,
    name: 'Janet Covarrubias',
    profession: 'Lic. Negocios Internacionales',
    location: 'Jalisco, México',
    review:
      'Tras graduarme de la universidad tuve la oportunidad de comenzar a trabajar para una empresa americana, pero era consciente de que mis ingresos se limitaban únicamente a la cantidad de proyectos que vendía de manera personal. En mi búsqueda constante de diversificación de ingresos, decidí emprender en este innovador modelo de negocios por 3 razones principales: flexibilidad y libertad de tiempo, potencial de crecimiento de ingresos ilimitado y la oportunidad de poder viajar',
    image: janet,
  },
  // Add more testimonials as needed
];

export default testimonials;
